
import Vue from "vue";
import moment from "moment";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { TableOptions } from "@/models/TableOptions";

export interface IbAfiliados {
  cadastro?: string;
  nome?: string;
  documento?: string;
  email?: string;
}

export default Vue.extend({
  name: "homeAfiliados",
  data() {
    return {
      moduloAtivo: false,
      emv: "",
      alert: {} as Alert,
      loadingData: true,
      btnloading: false,
      btnDisabled: true,
      dialogPixCopyPaste: false,
      isMobile: false,
      lang: navigator.language,
      desserts: new Array<IbAfiliados>(),
      errors: [],
      moment: moment,
      headers: [] as { text: string, value: string, sortable: boolean }[],
      breadcrumbs: [] as { text: string, disabled: boolean, href: string }[],
      mfaEnabled: true,
      hasInfoSituacaoConta: false,
      situacaoConta: {},
      loadedData: false,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      options: {} as TableOptions,
      totalDesserts: 0,
      timeout: 0,
      loadingDesserts: false,
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.updateHeaders();
    this.updateBreadcrumbs(); 
  },
  methods: {
    updateHeaders() {
      this.headers = [
        { text: this.$t("components.preCadastro.template.form.Name") as string, value: "nome", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Document") as string, value: "documento", sortable: false },
        { text: this.$t("components.preCadastro.template.form.registration") as string, value: "cadastro", sortable: false },
        { text: this.$t("components.preCadastro.template.form.Email") as string, value: "email", sortable: false },
      ];
    },
    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("components.preCadastro.template.form.Home") as string, 
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.Affiliates") as string, 
          disabled: false,
          href: "/afiliados/afiliados",
        },
        {
          text: this.$t("components.preCadastro.template.form.Affiliate Report") as string, 
          disabled: true,
          href: "/",
        }
      ];
    },
    detectMobile() {
      return screen.width <= 760;
    },
    doDelayedSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.loadDatas();
      }, 1000);
    },
    loadDatas() {
      const { page } = this.options;
      this.loadingDesserts = true;
      Api.get("/v2/client/afiliados/afiliados", {
        params: {
          q: this.search,
          page: page
        },
      })
      .then((response) => {
        this.desserts = response.data.body.rows as IbAfiliados[];
        this.totalDesserts = Number(response.data.body.count.__total);
      })
      .catch((error: any) => {
        const response = error.response as AxiosResponse;
        const status = response.status;
        const data = response.data;
      })
      .finally(() => {
        this.loadingDesserts = false;
      });
    },
  },
  watch: {
    options: {
      handler() {
        this.loadDatas();
        this.updateBreadcrumbs(); 
      },
    },
    search: {
      handler() {
        this.doDelayedSearch();
      },
    },
    '$i18n.locale': {
      handler() {
        this.updateHeaders();
        this.updateBreadcrumbs(); 
      },
      immediate: true,
    },
  },
});
