
import Vue from "vue";
import Api from "@/services/Api";
import { Overlay } from "@/lib/Overlay";
import { AxiosResponse } from "axios";
import { VMoney } from "v-money";
import moment from "moment";
import { TableOptions } from "@/models/TableOptions";
import { SwapQuote } from "@/models/SwapQuote";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";

const __DIALOG_COUNTDOWN_TIMEOUT_SEC = 45;

export interface Swaps {
  id: string;
  swap: any;
}
export default Vue.extend({
  name: "NewSwap",

  data() {
    return {
      
      loadingBalance: false,
      saldoDisponivel: "0,00",
      showSaldo: false,
      transactionPin: "",
      btnloading: false,
      OkCreateSwap: false,
      dialogConfirmSwap: false,
      QuoteRespJWT: [],
      showAmountToPayUSDT: true,
      showNetInverseRateAmountOut: false,
      netInverseRateAmountOut: "R$ 5,54 = 1 USDT",
      netRateAmountOut: "1 USDT = R$ 5,54",
      amountIn: {
        amountIn: "0.0000",
        amountInFormatado: "0,00",
        amountInUSD: "0,00",
        amountInEUR: "0,00",
      },
      minimalCoinValue: [{
          "BTC": {
            "value": "0.000850"
          }
        },
        {
          "ETH": {
            "value": "0.019"
          }
        },
        {
          "USDT": {
            "value": "25.00"
          }
        },
        {
          "USDC": {
            "value": "25.00"
          }
        },
        {
          "TRX": {
            "value": "36.00"
          }
        },
      ],
      maximalCoinValue: [{
          "BTC": {
            "valueMax": "1.57"
          }
        },
        {
          "ETH": {
            "valueMax": "38.00"
          }
        },
        {
          "USDT": {
            "valueMax": "89500.00"
          }
        },
        {
          "USDC": {
            "valueMax": "89550.00"
          }
        },
        {
          "TRX": {
            "valueMax": "579831.00"
          }
        },
      ],
      ticketPrice: "1 USDT = R$ 5,54",
      netAmountOut: {
        netAmountOut: "0.000000",
        netAmountOutFormatado: "0,00",
        netAmountOutEURFormatado: "0,00",
      },
      dialogSwap: false,
      serviceTax: "0,00",
      serviceTaxPerc: "0",

      withdrawTax: "0,00",
      blockchainTax: "0",
      addressOut: "p2p-mainnet",
      addressOutPlaceholder: "0x48d17...",
      defaultBtnCurrencyIn: {
        icon: require("@/assets/images/brl.svg"),
        namePt: "Real",
        sigla: "BRL",
      },
      defaultBtnCurrencyOut: {
        icon: require("@/assets/images/usdt.svg"),
        namePt: "Tether",
        sigla: "USDT",
      },
      defaultBtnNetWorkOut: {
        icon: require("@/assets/images/eth.svg"),
        namePt: "Ethereum (ERC20)",
      },
      errors: {} as Record < string,string > ,
      swapExactFiatForCrypto: false,
      swapFiatForExactCrypto: false,
      formQuote: {} as SwapQuote,

      loadingDesserts: true,
      totalDesserts: 0,
      options: {} as TableOptions,
      search: "",
      moment: moment,
      lang: navigator.language,
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      timeout: 0,
      markets: [], //JSON.parse(localStorage.getItem("SwapMarkets")),
      isMobile: false,
      isBtc: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */ ,
      },
      moneycoin: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 6,
        masked: false /* doesn't work with directive */ ,
      },
      moneyNoMask: {
        decimal: "",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 0,  // Precisão alta para moedas como BTC e ETH
        masked: false,
      },
      moneyBTC: {
        decimal: ",",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 8,
        masked: false /* doesn't work with directive */ ,
      },

      currencies: [] as any,
      marketsBTC: [] as any,
      marketsUSDT: [] as any,
      defaultMarket: [] as any,
      user: "",
      amountInBrl: "",
      rules: {
        required: [
    (value: string) => !!value || "Campo obrigatório.",  // Validação de valor negativo
  ]
      },
      netAmountOutBTC: "",
      okToQuote: true,
      countDown: __DIALOG_COUNTDOWN_TIMEOUT_SEC, //contador de tempo
      countDownCount: 0,
      countDownStart: true,
    };
  },

  created() {
    this.isMobile = this.detectMobile();

    this.getCurrencies();
    this.getBalanceSwap();
    this.formQuote.currencyIn = "BRL";
    this.formQuote.currencyOut = "USDT";
    this.formQuote.networkOut = "eth-mainnet";
    this.formQuote.netAmountOut = this.netAmountOut.netAmountOut;
    // this.countDownTimer();
    this.countDownStart = false;
  },

  methods: {
    onAmountInInput(value: string) {
      if (['BTC', 'ETH'].includes(this.defaultBtnCurrencyOut.sigla)) {
        // Para BTC e ETH, aceita a entrada como está
        this.amountInBrl = value;
      } else {
        // Para outras moedas, formata o valor
        const cleanedValue = value.replace(/\D/g, '');
        const numberValue = parseFloat(cleanedValue) / 100;
        this.amountInBrl = numberValue.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
    },
    updateAmountInFormatted() {
    const valor = this.removeFormatacaoMoeda(this.amountInBrl);

    // Verifica se a moeda é BTC para ajustar a formatação
    if (this.defaultBtnCurrencyOut.sigla === 'BTC') {
      this.amountIn.amountInFormatado = (parseFloat(valor) / Math.pow(10, 8)).toFixed(8);
    } else {
      this.amountIn.amountInFormatado = (parseFloat(valor) / 100).toFixed(2);
    }
  },
    getMinValue() {
      const currency = this.defaultBtnCurrencyOut.sigla;
      const minimalValue = this.minimalCoinValue.find((item) => currency in item) as {
        [key: string]: {
          value: string
        }
      } | undefined;
      return minimalValue ? parseFloat(minimalValue[currency].value) : 15; // Fallback para 15 se não encontrar o valor
    },

    getMaxValue() {
      const currency = this.defaultBtnCurrencyOut.sigla;
      const maxValueObject = this.maximalCoinValue.find((item) => currency in item) as {
        [key: string]: {
          valueMax: string
        }
      } | undefined;
      return maxValueObject ? parseFloat(maxValueObject[currency].valueMax.replace(',', '.')) : 500000; // Fallback para 500000 se não encontrar o valor
    },
    validateAmountIn() {
  const valorAmountIn = this.removeFormatacaoMoeda(this.amountInBrl);

  // Verifica se a moeda atual é BTC ou ETH e ajusta a precisão
  let valorAmountInFloat;
  if (this.defaultBtnCurrencyOut.sigla === 'BTC' || this.defaultBtnCurrencyOut.sigla === 'ETH') {
    valorAmountInFloat = parseFloat(valorAmountIn) / Math.pow(10, 5); // Ajuste para BTC/ETH
  } else {
    valorAmountInFloat = parseFloat(valorAmountIn) / 100; // Para outras moedas
  }

  // Verifica se o valor é negativo
  if (valorAmountInFloat < 0) {
    this.errors['amountIn'] = this.$t('components.preCadastro.template.form.NegativeAmountError').toString();
    this.OkCreateSwap = false;
    return false;
  }

  // Verifica se o valor é zero
  if (valorAmountInFloat === 0) {
    this.errors['amountIn'] = this.$t('components.preCadastro.template.form.AmountCannotBeZero').toString();
    this.OkCreateSwap = false;
    return false;
  }

  // Verifica se o valor é menor que o mínimo
  const minValue = this.getMinValue(); // Pega o valor mínimo da moeda atual
  if (valorAmountInFloat < minValue) {
    this.errors['amountIn'] = this.$t('components.preCadastro.template.form.MinimumAmountExceeded', {
      minValue
    }).toString();
    this.OkCreateSwap = false;
    return false;
  }

  // Verifica se o valor é maior que o máximo
  const maxValue = this.getMaxValue(); // Pega o valor máximo da moeda atual
  if (valorAmountInFloat > maxValue) {
    this.errors['amountIn'] = this.$t('components.preCadastro.template.form.AmountExceeded', {
      maxValue
    }).toString();
    this.OkCreateSwap = false;
    return false;
  }

  // Se tudo estiver correto, limpa os erros e ativa o botão
  this.errors['amountIn'] = ''; // Limpa erros
  this.OkCreateSwap = true; // Ativa o botão
  return true;
},


    recarregarGrid() {
      this.getMarkets(); // Chama o método que carrega os dados da grid
    },
    openDialoQuote() {
      this.resetFieldsQuote();
      this.getBalanceSwap();

      this.dialogSwap = true;
      this.countDownStart = false;
    },
    resetFieldsQuote() {
      this.dialogConfirmSwap = false;
      this.OkCreateSwap = false;
      this.btnloading = false;
      this.amountInBrl = "";
      this.netAmountOutBTC = "";
      let inputNetAmountOut =
        (document.getElementById("BTC") as HTMLInputElement) || {};
      inputNetAmountOut.value = "0";
      let inputAmountIn =
        (document.getElementById("BRL") as HTMLInputElement) || {};
      inputAmountIn.value = "0";
      this.formQuote.currencyIn = "BRL";
      this.formQuote.currencyOut = "USDT";
      this.formQuote.networkOut = "eth-mainnet";
      this.formQuote.amountIn = "";
      this.formQuote.netAmountOut = "";

      this.netInverseRateAmountOut = "R$ 5,54 = 1 USDT";
      this.netRateAmountOut = "1 USDT = R$ 5,54";
      (this.amountIn = {
  amountIn: "0.00086000", // Valor original de BTC ou outra moeda
  // Aplicar a formatação condicionalmente para BTC (com 8 casas decimais) ou outras moedas (com 2 casas decimais)
  amountInFormatado: this.defaultBtnCurrencyOut.sigla === 'BTC'
    ? parseFloat("0.00086000").toFixed(8) // Formatação específica para BTC com 8 casas decimais
    : parseFloat(this.amountIn.amountIn).toFixed(2), // Para outras moedas com 2 casas decimais
  amountInUSD: "50.00",  // Valor padrão ou real para USD, pode ser atualizado com o valor correto
  amountInEUR: "45.00",  // Valor padrão ou real para EUR, pode ser atualizado com o valor correto
}),

      (this.ticketPrice = "1 USDT = R$ 5,54");
      this.netAmountOut = {
        netAmountOut: "0.00000000",
        netAmountOutFormatado: "0,00",
        netAmountOutEURFormatado: "0,00",
      };
      this.transactionPin = "";
      this.QuoteRespJWT = [];
      this.serviceTax = "0,00";
      this.serviceTaxPerc = "0";
      this.withdrawTax = "0,00";
      this.blockchainTax = "0";
      this.addressOut = "p2p-mainnet";
      this.countDownStart = false;
      this.countDownCount = 0;
    },
    openDialogConfirmSwap() {
      if (!this.OkCreateSwap) {
        return; // Se OkCreateSwap for falso, impede de abrir o diálogo
      }
      this.dialogConfirmSwap = true;
    },
    cancelConfirmSwap() {
      this.resetFieldsQuote();
      this.transactionPin = "";
      this.dialogConfirmSwap = false;
      this.dialogSwap = true;
    },
    cancelSwap() {
      this.resetFieldsQuote();
      this.OkCreateSwap = false;
      this.dialogSwap = false;
    },
    createSwap() {
      this.btnloading = true;

      this.errors = {} as Record < string, string > ;

      if (this.transactionPin.length < 6) {
        const errorMessage = this.$t('components.preCadastro.template.form.pinInvalido') as string;
        this.errors["transactionPin"] = errorMessage;
        this.btnloading = false;
        return false;
      }

      this.OkCreateSwap = false;
      this.countDownStart = false;

      Overlay.show();
      this.btnloading = true;
      this.dialogConfirmSwap = false;
      Api.post("/v2/client/cripto/swaps/swap-reverse", {
          transactionPin: this.transactionPin,
          addressOut: this.addressOut,
          userId: sessionStorage.getItem("userCriptoId"),
          quoteData: this.QuoteRespJWT,
        })
        .then((response) => {
          this.dialogConfirmSwap = false;
          this.dialogSwap = false;
          this.addressOut = "p2p-mainnet";
          Swal.fire({
            title: this.$t('components.preCadastro.template.form.conversaoSucesso') as string,
            text: this.$t('components.preCadastro.template.form.aguardeProcessamento') as string,
            icon: "success",
          }).then(() => {
            // Emite um evento para informar que o diálogo Pix deve ser aberto
            this.$emit('open-dialog-pix');
          });
          this.$emit("callmethod", 0);
          this.resetFieldsQuote();
          this.countDownStart = false;
          this.dialogSwap = false;
          this.dialogConfirmSwap = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            Swal.fire({
              title: "Opps",
              text: data.body.error,
              icon: "error",
            });
          }
          this.dialogConfirmSwap = false;
          this.resetFieldsQuote();
          this.OkCreateSwap = false;
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.btnloading = false;
          this.OkCreateSwap = true;
          this.okToQuote = true;
          this.dialogConfirmSwap = false;
        });
    },
    //crypto para real
    fnSwapFiatForExactCrypto() {
      this.errors = {} as Record < string, string > ;
      this.swapExactFiatForCrypto = false;
      this.swapFiatForExactCrypto = true;

      this.formQuote.type = "swapFiatForExactCrypto";
    },
    fnSwapExactFiatForCrypto() {
      this.errors = {} as Record < string, string > ;
      this.swapExactFiatForCrypto = true;
      this.swapFiatForExactCrypto = false;
      this.formQuote.type = "swapExactFiatForCrypto";
    },
    makeBtnCurrencyIn(icon: string, namePt: string, sigla: string) {
      this.defaultBtnCurrencyIn.icon = require("@/assets/images/" + icon.toLocaleLowerCase() + ".svg");
      this.defaultBtnCurrencyIn.namePt = namePt;
      this.defaultBtnCurrencyIn.sigla = sigla;

      // Atualizar a moeda de entrada
      this.formQuote.currencyIn = sigla;

      // Definir a rede correspondente à moeda de entrada, se aplicável
      if (this.defaultMarket.length > 0) {
        this.formQuote.networkIn = this.defaultMarket[0].nameId; // Atualizar a networkIn conforme necessário
      }
    },

    makeBtnCurrencyOut(item: any) {
  // Limpar erros ao trocar de moeda
  this.errors['amountIn'] = ''; // Limpa erro de valor negativo ou mínimo excedido

  // Atualizar ícone e nome da moeda de saída
  this.defaultBtnCurrencyOut.icon = require("@/assets/images/" + item.currency.toLocaleLowerCase() + ".svg");
  this.defaultBtnCurrencyOut.namePt = item.name;
  this.defaultBtnCurrencyOut.sigla = item.currency;

  // Lógica de formatação para a moeda selecionada
  if (item.currency === "BTC" || item.currency === "ETH") {
    // Para BTC e ETH, sem formatação especial
    this.amountInBrl = ''; // Reseta o campo para aceitar a entrada livre
  } else {
    // Para outras moedas, reseta a entrada e aplica formatação de moeda
    this.amountInBrl = ''; // Reseta o campo
  }

  // Definir redes disponíveis para a moeda selecionada
  this.defaultMarket = item.deposit.networks;

  // Selecionar a primeira rede válida para a moeda selecionada
  if (this.defaultMarket.length > 0) {
    this.formQuote.networkOut = this.defaultMarket[0].nameId;
    this.defaultBtnNetWorkOut.icon = require("@/assets/images/" + this.defaultMarket[0].currencySymbol.toLocaleLowerCase() + ".svg");
    this.defaultBtnNetWorkOut.namePt = this.defaultMarket[0].description;
    this.addressOutPlaceholder = this.defaultMarket[0].placeholder;
  }

  // Atualizar a moeda de saída
  this.formQuote.currencyOut = item.currency;

  // Obter nova cotação
  this.getQuote();
},




    makeBtnNetworkOut(item: any) {
      // Atualizar a rede selecionada
      this.formQuote.networkOut = item.nameId;
      this.addressOutPlaceholder = item.placeholder;

      // Atualizar ícone e nome da rede
      this.defaultBtnNetWorkOut.icon = require("@/assets/images/" + item.currencySymbol.toLocaleLowerCase() + ".svg");
      this.defaultBtnNetWorkOut.namePt = item.description;
    },

    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },

    getMarkets() {
      this.loadingDesserts = true;
      Overlay.show();
      Api.get("/v2/client/cripto/swaps/markets")
        .then((response) => {
          this.markets = response.data.data;

          const markets = response.data.data;
          for (let index = 0; index < markets.length; index++) {
            const element = markets[index];

            if (element.baseCurrency.currency === "USDT") {
              this.marketsUSDT = markets[index].baseCurrency.withdraw
                .networks as any;
              this.defaultMarket = this.marketsUSDT;
            }
            if (element.baseCurrency.currency === "BTC") {
              this.marketsBTC = markets[index].baseCurrency.withdraw.networks;
            }
          }
        })
        .finally(() => {
          Overlay.hide();
          this.loadingDesserts = false;
          this.dialogSwap = false;
        });
    },

    getCurrencies() {
      this.loadingDesserts = true;
      Overlay.show();
      Api.get("/v2/client/cripto/swaps/currencies")
        .then((response) => {
          this.currencies = response.data.data;

          localStorage.removeItem("SwapCurrencies");
          localStorage.setItem(
            "SwapCurrencies",
            JSON.stringify(response.data.data)
          );
        })
        .finally(() => {
          this.getMarkets();
          Overlay.hide();
          this.loadingDesserts = false;
        });
    },

    removeFormatacaoMoeda($str: string) {
      return $str.replace(/\D/g, "");
    },

    validaAmountIn() {
      const valorAmountIn = this.removeFormatacaoMoeda(this.amountInBrl);
      const valorAmountInFloat = parseFloat(valorAmountIn) / 100;

      // Verifica se o valor é zero
      if (valorAmountInFloat === 0) {
        this.errors['amountIn'] = this.$t('components.preCadastro.template.form.AmountCannotBeZero').toString();
        this.OkCreateSwap = false;
        return false;
      }

      // Verifica se o valor é menor que o mínimo
      const minValue = this.getMinValue(); // Pega o valor mínimo
      if (valorAmountInFloat < minValue) {
        this.errors['amountIn'] = this.$t('components.preCadastro.template.form.MinimumAmountExceeded', {
          minValue
        }).toString();
        this.OkCreateSwap = false;
        return false;
      }

      // Verifica se o valor é maior que o máximo
      const maxValue = this.getMaxValue(); // Pega o valor máximo
      if (valorAmountInFloat > maxValue) {
        this.errors['amountIn'] = this.$t('components.preCadastro.template.form.AmountExceeded', {
          maxValue
        }).toString();
        this.OkCreateSwap = false;
        return false;
      }

      // Se tudo estiver correto, limpa os erros e ativa o botão
      this.errors['amountIn'] = ''; // Limpa erros
      this.OkCreateSwap = true; // Ativa o botão
      return true;
    },

    validaAmountOut() {
      const valorNetAmountOut = this.netAmountOutBTC;

      // Verifica se o valor é "0.00000", "0", vazio ou nulo
      if (valorNetAmountOut === "0.00000" || valorNetAmountOut === "0" || valorNetAmountOut === "" || valorNetAmountOut === null) {
        this.errors["netAmountOut"] = String(this.$t('components.preCadastro.template.form.MinimumAmountExceeded', {
          minValue: "0.000001"
        }));
        this.OkCreateSwap = false; // Bloqueia o botão de avançar
        return false;
      }

      // Se o valor for válido, limpa o erro e permite avançar
      this.errors["netAmountOut"] = ''; // Limpa qualquer erro anterior
      this.OkCreateSwap = true; // Libera o botão de avançar
      return true;
    },

    countDownTimer() {
      if (this.countDownStart && this.countDownCount <= 5) {
        setTimeout(() => {
          if (this.countDown > 0 && this.countDownStart) {
            this.countDown -= 1;
            this.countDownTimer();
          }

        }, 1000);

        if (this.countDown == 0) {
          this.countDownCount += 1;
          this.countDown = __DIALOG_COUNTDOWN_TIMEOUT_SEC;

          this.countDownStart = false;
          this.getQuote();
        }
        if (this.countDownCount == 5) {

          this.resetFieldsQuote();
          this.countDownStart = false;
        }

      }
    },

    getQuote() {
      this.errors = {};
      this.QuoteRespJWT = [];
      this.btnloading = true;
      this.OkCreateSwap = false;

      setTimeout(() => {
        let postParams = {};
        const availableNetworks = this.defaultMarket;
        const networkIn = availableNetworks.length === 1 ? availableNetworks[0].nameId : (this.formQuote.networkOut === 'p2p-mainnet' ? 'eth-mainnet' : this.formQuote.networkOut);
        const networkOut = this.formQuote.networkOut === 'p2p-mainnet' ? 'eth-mainnet' : this.formQuote.networkOut;

        if (this.swapExactFiatForCrypto) {
          // Usuário está trocando uma quantidade exata de cripto para BRL
          if (!this.validaAmountIn() || !this.amountInBrl) {

            this.btnloading = false;
            this.OkCreateSwap = false;
            return false;
          }
          postParams = {
            amountIn: this.amountInBrl.replace("", "").replace(".", "").replace(",", ".").replace(" ", "").trim(),
            currencyIn: this.defaultBtnCurrencyOut.sigla,
            currencyOut: 'BRL',
            networkOut: "p2p-mainnet",
            networkIn,
            user: sessionStorage.getItem("userCriptoId"),
            type: "swapCryptoForFiat",
            typeQuote: "sell",
          };
        } else if (this.swapFiatForExactCrypto) {
          // Usuário está trocando uma quantidade exata de BRL para cripto
          if (!this.validaAmountOut()) {
            Swal.fire({
              title: "Erro",
              text: "O valor inserido em 'Você vai receber' é inválido. Por favor, insira um valor válido.",
              icon: "error",
            });
            this.btnloading = false;
            this.OkCreateSwap = false;
            return false;
          }
          postParams = {
            netAmountOut: this.netAmountOutBTC,
            currencyIn: this.defaultBtnCurrencyOut.sigla,
            currencyOut: 'BRL',
            networkOut: "p2p-mainnet",
            networkIn,
            user: sessionStorage.getItem("userCriptoId"),
            type: "swapCryptoForFiat",
            typeQuote: "sell",
          };
        } else {
          // Não foi identificado um tipo de operação válido
          this.errors["operation"] = String("Erro ao identificar o tipo de operação. Verifique os valores inseridos.");
          Swal.fire({
            title: "Erro",
            text: "Tipo de operação não reconhecido.",
            icon: "error",
          });
          this.btnloading = false;
          this.OkCreateSwap = false;
          return;
        }

        // Iniciar o carregamento
        this.loadingDesserts = true;
        this.okToQuote = false;

        Api.get("/v2/client/cripto/swaps/quote-brl", {
            params: postParams,
          })
          .then((response) => {

            const dataResp = jwtDecode(response.data.body.quote) as any;
            this.QuoteRespJWT = response.data.body.quote;

            if (dataResp.data.status === "ok") {
              this.$nextTick(() => {
                if (this.swapExactFiatForCrypto) {
                  let inputNetAmountOut = document.getElementById("BTC") as HTMLInputElement;
                  inputNetAmountOut.value = dataResp.data.data.quote.netAmountOut.netAmountOut;
                } else {
                  let inputAmountIn = document.getElementById("BRL") as HTMLInputElement;
                  inputAmountIn.value = dataResp.data.data.quote.amountIn.amountInFormatado;
                }
              });

              // Atualiza os valores mínimos e máximos da resposta
              this.formQuote = {
                ...dataResp.data.data.quote,
                netAmountOutMin: {
                  netAmountOutMinFormatado: dataResp.data.data.quote.netAmountOutMinFormatado || null
                },
                netAmountOutMax: {
                  netAmountOutMaxFormatado: dataResp.data.data.quote.netAmountOutMaxFormatado || null
                }
              };

              this.netAmountOut = dataResp.data.data.quote.netAmountOut;
              this.amountIn = dataResp.data.data.quote.amountIn;
              this.serviceTax = dataResp.data.data.quote.fees.service.amount;
              this.serviceTaxPerc = dataResp.data.data.quote.fees.service.percentage.toString();
              this.blockchainTax = dataResp.data.data.quote.fees.network.amount + " " + dataResp.data.data.quote.fees.network.currency;
              this.netRateAmountOut = "1 " + dataResp.data.data.quote.netRateCurrencyOut + " = " + dataResp.data.data.quote.currencyIn + " " + dataResp.data.data.quote.netRateAmountOut;
              this.netInverseRateAmountOut = "1 " + dataResp.data.data.quote.currencyIn + " = " + dataResp.data.data.quote.netInverseRateAmountOut + " " + dataResp.data.data.quote.netRateCurrencyOut;

              this.OkCreateSwap = true; // Libera o botão
              this.countDownStart = true;
              this.countDown = __DIALOG_COUNTDOWN_TIMEOUT_SEC;
              this.countDownTimer();
            }
          })
          .catch((error: any) => {
            this.OkCreateSwap = false;
            const response = error.response as AxiosResponse;
            const status = response.status;
            const data = response.data;

            console.error('Erro na requisição:', error);

            if (status === 422) {
              this.errors = data.body.errors;
              Swal.fire({
                title: "Erro de Validação",
                text: "Ocorreu um erro nos dados enviados. Verifique os campos e tente novamente.",
                icon: "error",
              });
            } else if (status === 500 && data.body.error === "Amount_Negative_Error_50") {
              Swal.fire({
                title: "Erro",
                text: "Você digitou um valor abaixo do mínimo.",
                icon: "error",
              });
            } else if (status === 500 && data.body.error === "Only one parameter is required: 'amountIn' or 'netAmountOut'") {
              Swal.fire({
                title: "Erro",
                text: "Valor não aceito. Apenas um parâmetro é necessário: 'amountIn' ou 'netAmountOut'.",
                icon: "error",
              });
            } else {
              Swal.fire({
                title: "Erro",
                text: data.body.error || "Erro desconhecido. Por favor, tente novamente.",
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            }
          })
          .finally(() => {
            Overlay.hide();
            this.loadingDesserts = false;
            this.btnloading = false;
            setTimeout(() => {
              this.okToQuote = true;
            }, 3000);
          });
      }, 500);
    }
    ,
    getCurrencySymbol(symbol: string) {

      return require(`@/assets/images/${symbol.toLocaleLowerCase()}.svg`);
    },

    getBalanceSwap() {
      this.loadingBalance = true;

      Api.get("/v2/client/cripto/swaps/balance")
        .then((response) => {
          this.saldoDisponivel = response.data.body.swapBalanceFormatado;
        })
        .finally(() => {
          this.loadingBalance = false;
        });
    },
    
    doDelayedQuote() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.getQuote();
        this.okToQuote = true;
      }, 500);
    },
  },
  watch: {
    amountInBrl: {
      handler() {
        if (!this.validateAmountIn()) {
          this.OkCreateSwap = false; // Desativa o botão se a validação falhar
          return;
        }

        // Se passar na validação, ativa o botão e segue o fluxo de cotação
        this.OkCreateSwap = true;

        // Verifica se está permitido realizar a cotação
        if (this.okToQuote) {
          this.fnSwapExactFiatForCrypto();
          this.doDelayedQuote();
        }
      },
      immediate: true, // Executa o handler imediatamente na inicialização
    },
    netAmountOutBTC: {
      handler() {
        if (!this.validaAmountOut()) {
          return false;
        }
        if (this.okToQuote) {
          this.fnSwapFiatForExactCrypto();
          this.doDelayedQuote();
        }
      },
    },
  },
  directives: {
    money: VMoney,
    moneyBTC: VMoney
  },
});
