
import Vue from "vue";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import { Alert } from "@/lib/Alert";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";

interface Breadcrumb {
  text: string;
  disabled: boolean;
  href: string;
}



export default Vue.extend({
    name: "Comissionamento",
    data() {
        return {
            alert: {} as Alert,
            btnloading: false,
            btnDisabled: true,
            selectedAfiliado: { id: 0, nome: "TODOS" },
            afiliados: [{ id: 0, nome: "TODOS" }],
            hasLoadedData: false,
            isMobile: false,
            lang: navigator.language,
            startPeriod: moment().add(-1, "days").format("YYYY-MM-DD"),
            endPeriod: moment().format("YYYY-MM-DD"),
            startDate: moment().format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            defaultStartDate: moment().format("YYYY-MM-DD"),
            defaultEndDate: moment().format("YYYY-MM-DD"),
            maxDate: moment().format("YYYY-MM-DD"),
            errors: [],
            moment: moment,
            breadcrumbs: [] as Breadcrumb[], 
            mfaEnabled: true,
            hasInfoSituacaoConta: false,
            desserts: [] as any,
            search: "",
            loadingDesserts: true,
        };
    },
    created() {
        this.isMobile = this.detectMobile();
        this.updateBreadcrumbs(); 
        this.getAfiliados();
    },
    watch: {
    '$i18n.locale': function() {
      this.updateBreadcrumbs();
    }
  },
    methods: {
        detectMobile() {
            if (screen.width <= 760) {
                return true;
            } else {
                return false;
            }
        },
        updateBreadcrumbs() {
            this.breadcrumbs = [
                {
                    text: this.$t("components.preCadastro.template.form.Home") as string,
                    disabled: false,
                    href: "/",
                },
                {
                    text: this.$t("components.preCadastro.template.form.Affiliates")  as string,
                    disabled: false,
                    href: "/afiliados/afiliados",
                },
                {
                    text: this.$t("components.preCadastro.template.form.Commissions")  as string,
                    disabled: true,
                    href: "/",
                }
            ];
        },
        loadDatas() {
            const intervalo = Math.round(
                (new Date(this.endPeriod).getTime() -
                    new Date(this.startPeriod).getTime()) /
                (1000 * 3600 * 24)
            );
            if (intervalo > 31) {
                Snackbar.show(
                    "O intervalo de pesquisa não pode ser superior a 31 dias",
                    "error"
                );
                return false;
            }
            this.loadingDesserts = true;
            Api.get("/v2/client/afiliados/comissionamento", {
                params: {
                    startDate: this.startPeriod,
                    endDate: this.endPeriod,
                },
            })
                .then((response) => {
                    this.desserts = response.data.body;
                })
                .catch((error: any) => {
                    const response = error.response as AxiosResponse;
                    const status = response.status;
                    const data = response.data;
                })
                .finally(() => {
                    this.loadingDesserts = false;
                    this.hasLoadedData = true;
                });
        },
        getAfiliados() {

            this.loadingDesserts = true;
            Api.get("/v2/client/afiliados/afiliados")
                .then((response) => {
                    // this.afiliados.push(response.data.body.rows);
                    const rows = response.data.body.rows as any;
                    rows.forEach((element: any) => {
                        this.afiliados.push({ id: element.id, nome: element.nome })
                    });


                })
                .catch((error: any) => {
                    const response = error.response as AxiosResponse;
                    const status = response.status;
                    const data = response.data;

                })
                .finally(() => {
                    this.loadingDesserts = false;

                });
        },
    }
});
