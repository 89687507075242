
import Vue from "vue";
import Vuetify from 'vuetify'
import Api from "@/services/Api";
import Axios from "axios";
import { Overlay } from "@/lib/Overlay";
import { Snackbar } from "@/lib/Snackbar";
import { ParamType } from "ethers/lib/utils";
import { VMoney } from "v-money";

Vue.use(Vuetify);

export interface IbCustomer {
    name?: string;
    taxId?: string;
    phone?: string;
    email?: string;
    zipCode?: string;
    street?: string;
    number?: string;
    neighborhood?: string;
    city?: string;
    state?: string;
    complement?: string;
}
export interface IbCobrancas {
    createdAt?: string;
    transactionId?: string;
    externalReference?: string;
    billingType?: string;
    value?: string;
    totalValue?: string,
    dueDate?: string;
    description?: string;
    installmentCount?: number;
    installmentValue?: number;
    installmentNumber?: number;
    interestType?: string;
    interestValue?: number;
    fineType?: string;
    fineValue?: number,
    discountType?: string,
    discountValue?: number,
    discountDueDateLimitDays?: number,
    customer?: IbCustomer,
    maxOverdueDays?: number;
    notifyStatus?: boolean;
    notifyDaysNotifyBeforeDue?: number;
    paymentDate?: string;
    status?: string;
}

export default Vue.extend({
    name: "CriarCobranca",
    directives: { 
        money: VMoney 
    },
    data() {
        return {
            loadingData: false,
            searchNull: "",
            defaultSelected: { value: this.$t("components.preCadastro.template.form.upfront") as string, id: 1 },
            formsPayments: [
                { value: this.$t("components.preCadastro.template.form.upfront") as string, id: 1 },
                { value: this.$t("components.preCadastro.template.form.2times") as string, id: 2 },
                { value: this.$t("components.preCadastro.template.form.3times") as string, id: 3 },
                { value: this.$t("components.preCadastro.template.form.4times") as string, id: 4 },
                { value: this.$t("components.preCadastro.template.form.5times") as string, id: 5 },
                { value: this.$t("components.preCadastro.template.form.6times") as string, id: 6 },
                { value: this.$t("components.preCadastro.template.form.7times") as string, id: 7 },
                { value: this.$t("components.preCadastro.template.form.8times") as string, id: 8 },
                { value: this.$t("components.preCadastro.template.form.9times") as string, id: 9 },
                { value: this.$t("components.preCadastro.template.form.10times") as string, id: 10 },
                { value: this.$t("components.preCadastro.template.form.11times") as string, id: 11 },
                { value: this.$t("components.preCadastro.template.form.12times") as string, id: 12 },
            ],
            pageCount: 0,
            itemsPerPage: 50,
            activePicker: "YEAR",
            date: "",
            menuCalend: false,
            value: "",
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false,
            },
            totalValue: "",
            description: null || "",
            installmentCount: { id: 1, value: this.$t("components.preCadastro.template.form.upfront") as string },
            billingTypeDefault: { value: this.$t("components.preCadastro.template.form.hybrid") as string, id: "HYBRID" }, // default
            billingTypes: [
                { value: this.$t("components.preCadastro.template.form.bankSlip") as string, id: "BANKSLIP" },
                { value: this.$t("components.preCadastro.template.form.hybrid") as string, id: "HYBRID" },
            ],
            interestType: "PERCENTAGE", // juros
            interestValue: 0.00,
            fineType: "FIXED", // multa
            fineValue: 0.00,
            discountType: "PERCENTAGE",
            discountValue: 0.00,
            dueDate: "",
            dueDateLimitDays: 5,
            customerSelectDefault: { id: 0, name: this.$t("components.preCadastro.template.form.typeOrSelect") as string },
            searchClient: "",
            hasLoadedData: false,
            loadingDesserts: false,
            totalDesserts: 0,
            maxOverdueDays: 5,
            timeout: 0,
            page: 1,
            customer: {
                name: "",
                taxId: "",
                email: "",
                phone: "",
                telefone: "",
                zipCode: "",
                street: "",
                number: "",
                neighborhood: "",
                city: "",
                state: "",
                complement: "",
            },
            valueErrors: "",
            notify: {
                status: true,
                daysNotifyBeforeDue: 5
            },
            validDescription: false,
            errorMsgDescription: "",
            validValue: false,
            errorMsgValue: "",
            errorMsgDate: "",
            validDate: false,
            validCustomerName: false,
            errorMsgCustomerName: "",
            validCustomerDocument: false,
            errorMsgCustomerDocument: "",
            errosCustomer: 0,
            validCustomerEmail: false,
            errorMsgCustomerEmail: "",
            validCustomerPhone: false,
            errorMsgCustomerPhone: "",
            validCustomerZipCode: false,
            errorMsgCustomerZipCode: "",
            validCustomerStreet: false,
            errorMsgCustomerStreet: "",
            validCustomerNumber: false,
            errorMsgCustomerNumber: "",
            validCustomerNeighborhood: false,
            errorMsgCustomerNeighborhood: "",
            validCustomerCity: false,
            errorMsgCustomerCity: "",
            validCustomerState: false,
            errorMsgCustomerState: "",
            checkChargeInfos: false,
            errorApi: false,
            errorApiMsg: "",
        };
    },
    created() {
        this.updateTranslations();
    },
    methods: {
        updateTranslations() {
            this.defaultSelected.value = this.$t("components.preCadastro.template.form.upfront") as string;
            this.formsPayments.forEach(payment => {
                switch (payment.id) {
                    case 1:
                        payment.value = this.$t("components.preCadastro.template.form.upfront") as string;
                        break;
                    case 2:
                        payment.value = this.$t("components.preCadastro.template.form.2times") as string;
                        break;
                    case 3:
                        payment.value = this.$t("components.preCadastro.template.form.3times") as string;
                        break;
                    case 4:
                        payment.value = this.$t("components.preCadastro.template.form.4times") as string;
                        break;
                    case 5:
                        payment.value = this.$t("components.preCadastro.template.form.5times") as string;
                        break;
                    case 6:
                        payment.value = this.$t("components.preCadastro.template.form.6times") as string;
                        break;
                    case 7:
                        payment.value = this.$t("components.preCadastro.template.form.7times") as string;
                        break;
                    case 8:
                        payment.value = this.$t("components.preCadastro.template.form.8times") as string;
                        break;
                    case 9:
                        payment.value = this.$t("components.preCadastro.template.form.9times") as string;
                        break;
                    case 10:
                        payment.value = this.$t("components.preCadastro.template.form.10times") as string;
                        break;
                    case 11:
                        payment.value = this.$t("components.preCadastro.template.form.11times") as string;
                        break;
                    case 12:
                        payment.value = this.$t("components.preCadastro.template.form.12times") as string;
                        break;
                }
            });
            this.installmentCount.value = this.$t("components.preCadastro.template.form.upfront") as string;
            this.billingTypeDefault.value = this.$t("components.preCadastro.template.form.hybrid") as string;
            this.billingTypes.forEach(type => {
                if (type.id === "BANKSLIP") {
                    type.value = this.$t("components.preCadastro.template.form.bankSlip") as string;
                } else if (type.id === "HYBRID") {
                    type.value = this.$t("components.preCadastro.template.form.hybrid") as string;
                }
            });
            this.customerSelectDefault.name = this.$t("components.preCadastro.template.form.typeOrSelect") as string;
        },
        save() {
            this.menuCalend = false;
        },
        sendPostFields() {
            //antes de enviar para a API do BB, mostrar div com o resumo e ocultar formulario
            const formContent: IbCobrancas = {
                'value': this.value,
                'totalValue': this.totalValue,
                'description': this.clearCharacters(this.description),
                'installmentCount': this.installmentCount.id,
                'dueDate': this.date,
                'interestType': (this.interestType === "PERCENTAGE") ? "PERCENTAGE" : "FIXED",
                'interestValue': parseFloat(this.interestValue.toString()),
                'fineType': (this.fineType) ? "PERCENTAGE" : "FIXED",
                'fineValue': parseFloat(this.fineValue.toString()),
                'billingType': this.billingTypeDefault.id,
                'discountType': (this.discountType) ? "PERCENTAGE" : "FIXED",
                'discountValue': parseFloat(this.discountValue.toString()),
                'discountDueDateLimitDays': this.dueDateLimitDays,
                'customer': this.customer,
                'maxOverdueDays': this.maxOverdueDays,
                'notifyStatus': this.notify.status,
                'notifyDaysNotifyBeforeDue': this.notify.daysNotifyBeforeDue,
            };
            console.log(formContent);
            //validar campos
            this.validatePost(formContent);
            if (this.errosCustomer < 1) {
                this.checkChargeInfos = true;
                window.scrollTo(0, 0);
            } else {
                this.checkChargeInfos = false;
            }
            Overlay.hide();
            this.loadingDesserts = false;
        },
        createCharge() {
            Overlay.show();
            this.loadingDesserts = true;
            this.discountType = (this.discountType === 'false' || this.discountType === 'FIXED')? "FIXED" : "PERCENTAGE";
            this.fineType = (this.fineType === 'false' || this.fineType === 'FIXED')? "FIXED" : "PERCENTAGE";
            this.interestType = (this.interestType === 'false' || this.interestType === 'FIXED')? "FIXED" : "PERCENTAGE";
            Api.post("/v2/client/charges", {
                "billingType": this.billingTypeDefault.id,
                "value": this.value,
                "dueDate": this.date,
                "description": this.clearCharacters(this.description),
                //"externalReference": "", // ver como gerar esse {{$guid}}
                "installmentCount": this.installmentCount.id,
                "totalValue": this.value,
                "installmentValue": this.installmentCount.value,
                "maxOverdueDays": this.maxOverdueDays,
                "customer": {
                    "name": this.clearCharacters(this.customer.name),
                    "taxId": this.customer.taxId,
                    "phone": this.customer.phone,
                    "email": this.customer.email,
                    "zipCode": this.customer.zipCode,
                    "street": this.clearCharacters(this.customer.street),
                    "number": this.customer.number,
                    "complement": this.clearCharacters(this.customer.complement),
                    "neighborhood": this.clearCharacters(this.customer.neighborhood),
                    "city": this.clearCharacters(this.customer.city),
                    "state": this.customer.state
                },
                "discount": {
                    "type": this.discountType,
                    "value": parseFloat(this.discountValue.toString()).toFixed(2),
                    "dueDateLimitDays": this.dueDateLimitDays
                },
                "interest": {
                    "value": parseFloat(this.interestValue.toString()).toFixed(2),
                    "type": this.interestType
                },
                "fine": {
                    "value": parseFloat(this.fineValue.toString()).toFixed(2),
                    "type": (this.fineType) ? "PERCENTAGE" : "FIXED"
                },
                "notify": {
                    "status": this.notify.status,
                    "daysNotifyBeforeDue": this.notify.daysNotifyBeforeDue,
                }
            })
                .then((response) => {
                    console.log(response.data);
                    Snackbar.show("Cobrança gerada com sucesso...", "success");
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                        window.location.href = "/cobrancas";
                    }, 5000);
                    this.errorApi = false;
                })
                .catch((error) => {
                    this.errorApi = true;
                    window.scrollTo(0, 0);
                    if (error.response) {
                        const paragraph = error.response.data.message;
                        const searchTerm = 'Campo CPF pregador';
                        const indexOfFirst = paragraph.indexOf(searchTerm);

                        if (indexOfFirst > -1) {
                            this.errorApiMsg = "Cadastro de Cobranças aceita somente Pessoa Juridica, favor revise o Documento de CNPJ.";
                        } else {
                            this.errorApiMsg = "Ocorreu um erro na comunicação da API do parceiro [" + error.response.data.message + "].  Tente mais tarde ou acione o suporte.";
                        }
                        let campoBenfic = paragraph.indexOf("Campo texto utilizado benefic");
                        console.log(error.response.data.message);
                        console.log(campoBenfic.toString());
                        if (paragraph.indexOf("Campo texto utilizado benefic") > -1) {
                            this.errorApiMsg = "Campo texto de Descrição preenchido com dados inválidos.";
                        } else if (paragraph.indexOf("Para tipo de multa = 2, informar data e porcentagem,") > -1) {
                            this.errorApiMsg = "Para inserir uma multa deve-se escolher uma data limite de pagamento e uma porcentagem. Caso não queira aplicar multa digite zero (0)";
                        } else {
                            this.errorApiMsg = paragraph;
                        }

                    }
                    Overlay.hide();

                })
                .finally(() => {
                    Overlay.hide();
                    this.loadingDesserts = false;
                });
        },
        formatDate(date: string): string | null {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        doDelayedSearch() {
            this.page = 1;
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                //this is your existing function
                this.loadDatas();
            }, 1000);
        },
        loadDatas() {
            this.hasLoadedData = true;
            Overlay.show();

            this.loadingData = true;
            this.clearCustomer();

            this.loadingDesserts = true;
            if (this.searchClient !== "") {
                Api.get("/v2/client/search", {
                    params: {
                        query: this.searchClient,
                    },
                })
                    .then((response) => {
                        this.loadingDesserts = false;
                        this.loadingData = false;

                        if (response.data.body.count.__total > 0) {
                            ///carregar formulario de customer
                            this.customer.name = response.data.body.rows[0].name;
                            this.customer.taxId = response.data.body.rows[0].taxId;
                            this.customer.email = response.data.body.rows[0].email;
                            this.customer.phone = response.data.body.rows[0].phone;
                            this.customer.zipCode = response.data.body.rows[0].zipCode;
                            this.customer.street = response.data.body.rows[0].street;
                            this.customer.number = response.data.body.rows[0].number;
                            this.customer.neighborhood = response.data.body.rows[0].neighborhood;
                            this.customer.city = response.data.body.rows[0].city;
                            this.customer.state = response.data.body.rows[0].state;
                            this.customer.complement = response.data.body.rows[0].complement;
                            this.searchNull = "1";
                        } else {
                            this.searchNull = "Nenhum registro encontrado...";
                        }
                    })
                    .catch((error: any) => {
                        if (typeof error !== undefined || typeof error !== 'undefined') {
                            const response = error.response;
                            this.searchNull = "Ocorreu um erro no servidor, tente mais tarde...";
                            const status = response.status;

                            if (status != 200) {
                                Snackbar.show(response.data.body.error, "error");
                            }
                            // this.resetRecaptcha();

                        }

                    })
                    .finally(() => {
                        Overlay.hide();
                    });
            } else {
                this.loadingDesserts = false;
                this.loadingData = false;
                Overlay.hide();
            }
            Overlay.hide();
            this.loadingDesserts = false;
        },
        clearCustomer() {
            this.customer.name = "";
            this.customer.taxId = "";
            this.customer.email = "";
            this.customer.phone = "";
            this.customer.zipCode = "";
            this.customer.street = "";
            this.customer.number = "";
            this.customer.neighborhood = "";
            this.customer.city = "";
            this.customer.state = "";
            this.customer.complement = "";
        },
        clearCustomerAddress() {
            this.customer.street = "";
            this.customer.number = "";
            this.customer.neighborhood = "";
            this.customer.city = "";
            this.customer.state = "";
            this.customer.complement = "";
        },
        clearCharacters(txt: string | any) {
            /* eslint-disable no-useless-escape */
            return txt.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        },
        validatePost(formContent: IbCobrancas) {
            const valueTraits: any = formContent.value;
            if (valueTraits.toString() === "0" || isNaN(valueTraits.toString())) {
                this.validValue = true;
                this.errorMsgValue = "Campo Valor obrigatório.";
                const valueForm = this.$refs.value as any;
                valueForm.focus();
            } else {
                this.validValue = false;
            }
            if (formContent.description == "") {
                this.validDescription = true;
                this.errorMsgDescription = "Campo descrição obrigatório.";
                const descriptionForm = this.$refs.description as any;
                descriptionForm.focus();

            } else {
                this.validDescription = false;
            }
            if (this.date === "") {
                this.validDate = true;
                this.errorMsgDate = "Campo Data Vencimento obrigatório.";
                const dateForm = this.$refs.date as any;
                dateForm.focus();

            } else if (!this.compareDates(this.date)) {
                this.validDate = true;
                this.errorMsgDate = "Campo Data Vencimento deve ser maior ou igual a data de hoje.";
                const dateForm = this.$refs.date as any;
                dateForm.focus();
            } else {
                this.validDate = false;
            }
            //Informacoes do Cliente do Cliente
            if (this.customer.name == "") {
                this.validCustomerName = true;
                this.errorMsgCustomerName = "Campo Nome do Cliente obrigatório.";
                const customerNameForm = this.$refs.customerName as any;
                customerNameForm.focus();
                this.errosCustomer = this.errosCustomer + 1;

            } else {
                this.validCustomerName = false;
                this.errosCustomer = (this.errosCustomer > 0) ? this.errosCustomer - 1 : 0;
            }
            if (this.customer.taxId == "") {
                this.validCustomerDocument = true;
                this.errorMsgCustomerDocument = "Campo Documento (CNPJ/CPF) do Cliente obrigatório.";
                const customerTaxIdForm = this.$refs.customerTaxId as any;
                customerTaxIdForm.focus();
                this.errosCustomer = this.errosCustomer + 1;

            } else {
                this.validCustomerDocument = false;
                this.errosCustomer = (this.errosCustomer > 0) ? this.errosCustomer - 1 : 0;
            }
            if (this.customer.email == "") {
                this.validCustomerEmail = true;
                this.errorMsgCustomerEmail = "Campo E-mail do Cliente obrigatório.";
                const customerEmailForm = this.$refs.customerEmail as any;
                customerEmailForm.focus();
                this.errosCustomer = this.errosCustomer + 1;

            } else {
                this.validCustomerEmail = false;
                this.errosCustomer = (this.errosCustomer > 0) ? this.errosCustomer - 1 : 0;
            }
            if (this.customer.phone === "") {
                this.validCustomerPhone = true;
                this.errorMsgCustomerPhone = "Campo de Telefone Celular do Cliente obrigatório.";
                const customerPhoneForm = this.$refs.customerPhone as any;
                customerPhoneForm.focus();
                this.errosCustomer = this.errosCustomer + 1;

            } else {
                this.validCustomerPhone = false;
                this.errosCustomer = (this.errosCustomer > 0) ? this.errosCustomer - 1 : 0;
            }
            if (this.customer.zipCode === "") {
                this.validCustomerZipCode = true;
                this.errorMsgCustomerZipCode = "Campo de CEP do Cliente obrigatório.";
                const customerZipCodeForm = this.$refs.customerZipCode as any;
                customerZipCodeForm.focus();
                this.errosCustomer = this.errosCustomer + 1;

            } else {
                this.validCustomerZipCode = false;
                this.errosCustomer = (this.errosCustomer > 0) ? this.errosCustomer - 1 : 0;
            }
            if (this.customer.street === "") {
                this.validCustomerStreet = true;
                this.errorMsgCustomerStreet = "Campo de Logradouro / Rua / Av. do Cliente obrigatório.";
                const customerStreetForm = this.$refs.customerStreet as any;
                customerStreetForm.focus();
                this.errosCustomer = this.errosCustomer + 1;

            } else {
                this.validCustomerStreet = false;
                this.errosCustomer = (this.errosCustomer > 0) ? this.errosCustomer - 1 : 0;
            }
            if (this.customer.number === "") {
                this.validCustomerNumber = true;
                this.errorMsgCustomerNumber = "Campo de número da rua / Av. etc... é obrigatório.";
                const customerNumberForm = this.$refs.customerNumber as any;
                customerNumberForm.focus();
                this.errosCustomer = this.errosCustomer + 1;

            } else {
                this.validCustomerNumber = false;
                this.errosCustomer = (this.errosCustomer > 0) ? this.errosCustomer - 1 : 0;
            }
            if (this.customer.neighborhood === "") {
                this.validCustomerNeighborhood = true;
                this.errorMsgCustomerNeighborhood = "Campo Bairro é obrigatório.";
                const customerNeighborhoodForm = this.$refs.customerNeighborhood as any;
                customerNeighborhoodForm.focus();
                this.errosCustomer = this.errosCustomer + 1;

            } else {
                this.validCustomerNeighborhood = false;
                this.errosCustomer = (this.errosCustomer > 0) ? this.errosCustomer - 1 : 0;
            }
            if (this.customer.city === "") {
                this.validCustomerCity = true;
                this.errorMsgCustomerCity = "Campo Cidade | Município é obrigatório.";
                const customerCityForm = this.$refs.customerCity as any;
                customerCityForm.focus();
                this.errosCustomer = this.errosCustomer + 1;

            } else {
                this.validCustomerCity = false;
                this.errosCustomer = (this.errosCustomer > 0) ? this.errosCustomer - 1 : 0;
            }
            if (this.customer.state === "") {
                this.validCustomerState = true;
                this.errorMsgCustomerState = "Campo Estado | UF é obrigatório.";
                const customerStateForm = this.$refs.customerState as any;
                customerStateForm.focus();
                this.errosCustomer = this.errosCustomer + 1;

            } else {
                this.validCustomerState = false;
                this.errosCustomer = (this.errosCustomer > 0) ? this.errosCustomer - 1 : 0;
            }
            setTimeout(function () {
                Overlay.hide();
            }, 1000);
        },
        compareDates(d1: string) {
            let date1 = new Date(d1).getTime();

            const today = new Date();
            const yyyy = today.getFullYear();
            let mm: string = (today.getMonth() + 1).toString(); // Months start at 0!
            let dd: string = today.getDate().toString();

            if (parseInt(dd) < 10) dd = '0' + dd;
            if (parseInt(mm) < 10) mm = '0' + mm;
            let dateNow = yyyy + "-" + mm + "-" + dd;

            let date2 = new Date(dateNow).getTime();
            if (date1 < date2) {
                return false;
            } else if (date1 >= date2) {
                return true;
            } else {
                return false;
            }
        },
        searchZipCode() {
            Overlay.show();
            const zipCode = this.customer.zipCode;
            const ulrConsult = "https://viacep.com.br/ws/" + zipCode + "/json";
            this.clearCustomerAddress();
            if (zipCode !== "" && zipCode.length > 7) {
                Axios.get(ulrConsult)
                    .then((response) => {
                        this.customer.complement = response.data.complemento;
                        this.customer.street = response.data.logradouro;
                        this.customer.neighborhood = response.data.bairro;
                        this.customer.city = response.data.localidade;
                        this.customer.state = response.data.uf;
                        Overlay.hide();
                    })
                    .catch((error) => {
                        console.log(error);
                        Overlay.hide();
                    });
            }
            return ulrConsult;
        },
        validateCPF() {
            let cpf = this.customer.taxId;
            if (cpf.length <= 11) {
    
                if(!this.validateCPFNew(cpf)){  
                    this.hideLisHtml();
                    this.validCustomerDocument = true;
                    this.errorMsgCustomerDocument = "Campo Documento CPF inválido.";
                    const customerDocumentForm = this.$refs.customerTaxId as any;
                    customerDocumentForm.focus();
                    this.errosCustomer = this.errosCustomer + 1;
                    return false;

                } else {
                    this.validCustomerDocument = false;
                    this.errosCustomer = (this.errosCustomer > 0) ? this.errosCustomer - 1 : 0;
                    return true;
                }
            } else if (cpf.length === 14) {
                this.validateNewCnpj(cpf);
            }
        },
        validateCPFNew(cpf: string){
            cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres especiais
            if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false; // Verifica se é uma sequência inválida

            let sum = 0;
            let rest;
            for (let i = 1; i <= 9; i++) {
                sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
            }
            rest = (sum * 10) % 11;
            if (rest === 10 || rest === 11) rest = 0;
            if (rest !== parseInt(cpf.substring(9, 10))) return false;

            sum = 0;
            for (let i = 1; i <= 10; i++) {
                sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
            }
            rest = (sum * 10) % 11;
            if (rest === 10 || rest === 11) rest = 0;
            return rest === parseInt(cpf.substring(10, 11));
        },
        validateNewCnpj(cnpj: string) {
            cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres especiais
            if (cnpj.length !== 14 || /^(\d)\1{13}$/.test(cnpj)) return false; // Verifica se é uma sequência inválida

            let size = cnpj.length - 2;
            let numbers = cnpj.substring(0, size);
            let digits = cnpj.substring(size);
            let sum = 0;
            let pos = size - 7;

            for (let i = size; i >= 1; i--) {
                sum += parseInt(numbers.charAt(size - i)) * pos--;
                if (pos < 2) pos = 9;
            }

            let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
            if (result !== parseInt(digits.charAt(0))) return false;

            size++;
            numbers = cnpj.substring(0, size);
            sum = 0;
            pos = size - 7;
            for (let i = size; i >= 1; i--) {
                sum += parseInt(numbers.charAt(size - i)) * pos--;
                if (pos < 2) pos = 9;
            }
            result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
            return result === parseInt(digits.charAt(1));
        },
        hideLisHtml() {
            this.validCustomerName = false;
            this.validCustomerEmail = false;
            this.validCustomerPhone = false;
            this.validCustomerZipCode = false;
            this.validCustomerStreet = false;
            this.validCustomerNeighborhood = false;
            this.validCustomerCity = false;
            this.validCustomerState = false;
            this.validCustomerNumber = false;
        },
        formatarCampo(campoTexto: string) {
            if (campoTexto.length <= 11) {
                campoTexto = this.mascaraCpf(campoTexto);
            } else {
                campoTexto = this.mascaraCnpj(campoTexto);
            }
            return campoTexto;
        },
        mascaraCpf(valor: string) {
            /* eslint-disable no-useless-escape */
            return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
        },
        mascaraCnpj(valor: string) {
            /* eslint-disable no-useless-escape */
            return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
        },
        returnFormTop() {
            this.checkChargeInfos = false;
            const retInputForm = this.$refs.retForm as any;
            retInputForm.focus();
        },
    },
    watch: {
        menuCalendar(val: string) {
            return val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        options: {
            handler() {
                this.loadDatas();
            },
        },
        '$i18n.locale': {
            handler() {
                this.updateTranslations();
            },
            immediate: true,
        },
    },
});
