
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { PesquisaUsuario } from "@/models/PesquisaUsuario";
import { Transferencias } from "@/models/Transferencias";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import { VMoney } from "v-money";

export default Vue.extend({
  name: "Transferencias",

  data() {
    return {
      dadosComprovanteTransferencia: [] as any,
      dialogComprovanteTransferencia: false,
      panelFavoritos: null,
      dialogPIN: false,
      pinCode: "",
      isMobile: false,
      btnLoading: false,
      reveal: false,
      marker: false,
      formPesquisaUsuario: {} as PesquisaUsuario,
      formTransferencia: {} as Transferencias,
      valid: true,
      exibeSaldo: true,
      saldoDisponivel: "0,00",
      loadingData: true,
      lang: navigator.language,
      moment: moment,
      dataUsuarioPesquisado: {} as any,
      hasDataUsuarioPesquisado: false,
      userToTransfer: 0,
      salvarFavorito: true,
      loadingFavoritos: true,
      favoritos: {} as any,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.getSaldo();
    this.getFavoritos();
  },

  methods: {
    showDialogPin() {
      //console.log(this.formTransferencia.valor);
      let valor = this.formTransferencia.valor.replace("0,00", "");
      valor.replace(" ", "");
      valor.replace("R$ ", "");
      //console.log(valor);

      if (this.formTransferencia.valor == "R$ 0,00") {
        Snackbar.show("Informe um valor", "error");
        return false;
      }
      if (this.formTransferencia.valor == "0,00") {
        Snackbar.show("Informe um valor", "error");
        return false;
      }
      if (this.formTransferencia.valor == "0,00") {
        Snackbar.show("Informe um valor", "error");
        return false;
      }
      this.dialogPIN = true;
      this.$nextTick(() => {
        this.focusPin();
      });
    },
    closeDialogPin() {
      this.pinCode = "";
      this.dialogPIN = false;
    },
    focusPin() {
      this.$nextTick(() => {
        const wrapper = this.$refs.pincode as any;
        const cells = wrapper.$el.getElementsByClassName(
          "vue-pincode-input"
        ) as any;

        window.setTimeout(() => wrapper?.focus(), 0);
      });
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    setUserToTransfer(data: any) {
      this.panelFavoritos = null;
      // console.log(data);

      Overlay.show();
      this.reveal = true;
      this.dataUsuarioPesquisado = data;
      this.hasDataUsuarioPesquisado = true;
      this.userToTransfer = data.id;
      this.formTransferencia.para = data.id;
      this.$nextTick(() => {
        const wrapper = this.$refs.valorTransferencia as any;
        window.setTimeout(() => wrapper?.focus(), 100);
        window.setTimeout(() => Overlay.hide(), 100);
      });
    },

    transfere() {
      if (this.pinCode.length != 6) {
        this.focusPin();
        Snackbar.show("Informe seu PIN", "error");
        return false;
      }
      this.dialogPIN = false;
      this.btnLoading = true;
      Overlay.show();

      this.formTransferencia.transactionPin = this.pinCode;
      Api.post(
        "v2/client/financeiro/transferencias/transferir",
        this.formTransferencia
      )
        .then((response) => {
          //

          this.showComprovante(response.data.body.transacao);
          this.resetTransferencia();

          this.getFavoritos();
          this.getSaldo();
          this.closeDialogPin();

          this.pinCode = "";
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
        })
        .finally(() => {
          this.btnLoading = false;

          Overlay.hide();
        });
    },
    resetTransferencia() {
      this.hasDataUsuarioPesquisado = false;
      this.formTransferencia.valor = "";
      this.formTransferencia.favorito = false;
      this.dataUsuarioPesquisado = {};
      this.reveal = false;
      this.pinCode = "";
      this.formTransferencia.identificacao = "";
      this.formPesquisaUsuario.query = "";
      this.formTransferencia.transactionPin = "";
    },
    removeFavorito(id: number) {
      Swal.fire({
        title: "Confirmação",
        text: "Confirma a remoção do Favorito?",
        icon: "question",
        iconColor: "red",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Overlay.show();
          this.btnLoading = true;
          //this.dialogAdicionaContaBancaria = false;
          Api.post("v2/client/financeiro/transferencias/favoritos/delete/" + id)
            .then((response) => {
              //
              Snackbar.show("Favorito Removido", "success");
              this.getFavoritos();
            })
            .catch((error: any) => {
              const response = error.response as AxiosResponse;
              const status = response.status;
              const data = response.data;

              Swal.fire({
                text: data.body.error,
                icon: "error",
                confirmButtonText: "Ok",
                showConfirmButton: true,
                customClass: {
                  confirmButton: "d-block",
                },
              });
            })
            .finally(() => {
              this.btnLoading = false;

              Overlay.hide();
            });
        }
      });
    },
    getFavoritos() {
      Overlay.show();
      (this.loadingFavoritos = true),
        Api.get("/v2/client/financeiro/transferencias/favoritos/index")
          .then((response) => {
            this.favoritos = response.data.body.rows;

            this.loadingFavoritos = false;
          })
          .catch((error: any) => {
            // this.resetRecaptcha();
          })
          .finally(() => {
            this.loadingFavoritos = false;
            Overlay.hide();
          });
    },
    getSaldo() {
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/client/financeiro/saldo")
        .then((response) => {
          this.saldoDisponivel = response.data.body.saldoFormatado;

          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },

    pesquisaUsuario() {
      if (
        this.formPesquisaUsuario.query == undefined ||
        this.formPesquisaUsuario.query == null
      ) {
        return false;
      } else {
        if (this.formPesquisaUsuario.query.length < 5) {
          Snackbar.show("Informe pelo menos 5 caracteres", "error");
          return false;
        }
      }
      this.hasDataUsuarioPesquisado = false;
      Overlay.show();

      Api.get(
        "v2/client/financeiro/transferencias/usuarios?query=" +
          this.formPesquisaUsuario.query
      )
        .then((response) => {
          this.dataUsuarioPesquisado = response.data.body;
          this.hasDataUsuarioPesquisado = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },

    showComprovante(id: string) {
      Overlay.show();

      Api.get("/v2/public/comprovantes/transferencias/" + id)
        .then((response) => {
          this.dadosComprovanteTransferencia = response.data.body;

          this.dialogComprovanteTransferencia = true;
        })
        .catch((error: any) => {
          Snackbar.show("Não foi possível recuperar o comprovante.");
        })
        .finally(() => {
          Overlay.hide();
        });
    },
  },
  directives: { money: VMoney },
});
