
import { defineComponent } from "vue";
import Api from "@/services/Api";
import { TableOptions } from "@/models/TableOptions";
import Axios, { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";

interface Movimento {
  id: string;
  descricao: string;
}

export interface IbMovimentos {
  movimento_cod?: string;
  movimento_transacao?: string;
  movimento_pessoa?: string;
  movimento_tipo?: string;
  movimento_data?: string;
  movimento_origem?: number;
  movimento_origem_pessoa?: string;
  movimento_destino_pessoa?: string;
  movimento_valor?: string;
  movimento_historico?: string;
  movimento_ref?: string;
  movimento_icone?: string;
  movimento_id_transferencia_api?: string;
  movimento_computasaldo?: number;
  movimento_canal?: string;
}

export default defineComponent({
  data() {
    return {
      showSaldo: false,
      loadingBalance: false,
      hasLoadedData: false,
      dialogComprovanteTransferencia: false,
      dialogComprovanteSaque: false,
      dialogComprovantePixEnviado: false,
      dialogComprovantePixRecebido: false,
      dialogComprovanteBoleto: false,
      dialogComprovanteCartao: false,
      dialogTransferSaldo: false,
      dialogFiltrosMobile: false,
      dialogComprovanteRecarga: false,
      panel: [] as number[],
      startPeriod: moment().add(-1, "hours").format("YYYY-MM-DDTHH:mm"),
      endPeriod: moment().format("YYYY-MM-DDT23:59"),
      defaultStartPeriod: moment().add(-1, "hours").format("YYYY-MM-DDTHH:mm"),
      defaultEndPeriod: moment().format("YYYY-MM-DDTHH:mm"),
      startDate: moment().format("YYYY-MM-DD"),
      defaultStartDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      defaultEndDate: moment().format("YYYY-MM-DD"),
      maxDate: moment().format("YYYY-MM-DD"),
      lang: navigator.language,
      btnReloadLoading: false,
      btnDownloadDisabled: true,
      btnDownloadLoading: false,
      moment: moment,
      filtrosMobile: "",
      search: "",
      page: 1,
      errors: [] as any,
      pageCount: 0,
      itemsPerPage: 10,
      totalEntradas: 0,
      totalSaidas: 0,
      saldoCliente: 0,
      somaDaPagina: 0,
      somaTotal: 0,
      menuInitialDate: false,
      pages: 0,
      menuFinalDate: false,
      menuInitialDateMobile: false,
      menuFinalDateMobile: false,
      dadosComprovanteTransferencia: [] as any,
      dadosComprovanteSaque: [] as any,
      dadosComprovanteBoleto: [] as any,
      dadosComprovanteCartao: [] as any,
      dadosComprovanteRecarga: [] as any,
      dadosComprovantePixEnviado: [] as any,
      dadosComprovantePixRecebido: [] as any,
      options: {} as TableOptions,
      toExport: false,
      selectedEntryTipoMovimentos: { id: "T", descricao: "Tudo" },
      tipoMovimentos: [
        { id: "T", descricao: "All" },
        { id: "C", descricao: "Inputs" },
        { id: "D", descricao: "Outputs" },
      ] as Movimento[],
      translatedTipoMovimentos: [] as { id: string; descricao: string; }[],
      selectedEntryFiltrosMovimentos: { id: "T", descricao: "Todos" },
      filtrosMovimentos: [
        { id: "T", descricao: "All" },
        { id: "46", descricao: "WithPixRec" },
        { id: "47", descricao: "WithPixPay" },
        { id: "51", descricao: "EstPixTransfer" },
        { id: "40", descricao: "EstPixPayment" },
        { id: "61", descricao: "GGRCredit" },
        { id: "60", descricao: "GGRDebit" },
        { id: "62", descricao: "GGRFee" },
        { id: "21", descricao: "BankSlipPayment" },
        { id: "35", descricao: "PixReceived" },
        { id: "36", descricao: "PixSentAPI" },
        { id: "37", descricao: "PixReversed" },
        { id: "55", descricao: "PixReturned" },
        { id: "53", descricao: "PixTransfers" },
        { id: "38", descricao: "PixSentFee" },
        { id: "39", descricao: "PixReceivedFee" },
        { id: "49", descricao: "PixTransferFee" },
        { id: "16", descricao: "MovementFee" },
        { id: "15", descricao: "BankWithdrawalFee" },
        { id: "6", descricao: "TransferSent" },
        { id: "5", descricao: "TransferReceived" },
        { id: "41", descricao: "PixRefund" },
        { id: "10", descricao: "WithdrawalToBankAccount" },
      ] as Movimento[],
      translatedFiltrosMovimentos: [] as { id: string; descricao: string; }[],
      desserts: {} as any,
      loadingDesserts: false,
      totalDesserts: 0,
      timeout: 0,
    };
  },
  computed: {
    translatedTipoMovimentos(): Movimento[] {
      return this.tipoMovimentos.map((item: Movimento) => ({
        ...item,
        descricao: this.$t(
          `components.preCadastro.template.form.${item.descricao.replace(
            /\s/g,
            ""
          )}`
        ) as string,
      }));
    },
    translatedFiltrosMovimentos(): Movimento[] {
      return this.filtrosMovimentos.map((item: Movimento) => ({
        ...item,
        descricao: this.$t(
          `components.preCadastro.template.form.${item.descricao.replace(
            /\s/g,
            ""
          )}`
        ) as string,
      }));
    },
  },
  created() {
    this.getBalance();
    this.translateTipoMovimentos();
    this.translateFiltrosMovimentos();
  },
  watch: {
    '$i18n.locale'() {
      this.translateTipoMovimentos();
      this.translateFiltrosMovimentos();
    },
  },
  methods: {
    translateTipoMovimentos() {
      this.translatedTipoMovimentos = this.tipoMovimentos.map(tipo => {
        const translationKey = `components.Movimento.template.MovementTypes.${tipo.descricao}`;
        return {
          id: tipo.id,
          descricao: this.$t(translationKey) as string
        };
      });
    },
    translateFiltrosMovimentos() {
      this.translatedFiltrosMovimentos = this.filtrosMovimentos.map(filtro => {
        const translationKey = `components.Movimento.template.MovementFilters.${filtro.descricao}`;
        return {
          id: filtro.id,
          descricao: this.$t(translationKey) as string
        };
      });
    },
    Comprovante(origem: number, id: string) {
      Overlay.show();
      let pathUri = "";
      if (origem == 6 || origem == 5) {
        pathUri = "comprovantes/transferencias/" + id;
      }
      if (origem == 10) {
        pathUri = "comprovantes/saques/" + id;
      }

      if (origem == 21) {
        pathUri = "comprovantes/boleto/" + id;
      }
      if (origem == 25) {
        pathUri = "comprovantes/cartao/" + id;
      }
      if (origem == 18) {
        pathUri = "comprovantes/recargas/" + id;
      }
      if (origem == 35 || origem == 34) {
        pathUri = "comprovantes/pix/receive/" + id;
      }
      if (origem == 36) {
        pathUri = "comprovantes/pix/sent/" + id;
      }

      Api.get("/v2/public/" + pathUri)
        .then((response) => {
          if (origem == 6 || origem == 5) {
            this.dadosComprovanteTransferencia = response.data.body;

            this.dialogComprovanteTransferencia = true;
          }

          if (origem == 10) {
            this.dadosComprovanteSaque = response.data.body;
            this.dialogComprovanteSaque = true;
          }

          if (origem == 21) {
            this.dadosComprovanteBoleto = response.data.body;
            this.dialogComprovanteBoleto = true;
          }
          if (origem == 25) {
            this.dadosComprovanteCartao = response.data.body;
            this.dialogComprovanteCartao = true;
          }
          if (origem == 18) {
            this.dadosComprovanteRecarga = response.data.body;
            this.dialogComprovanteRecarga = true;
          }
          if (origem == 36) {
            this.dadosComprovantePixEnviado = response.data.body;
            this.dialogComprovantePixEnviado = true;
          }
          if (origem == 35 || origem == 34) {
            this.dadosComprovantePixRecebido = response.data.body;
            this.dialogComprovantePixRecebido = true;
          }
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          Snackbar.show(data.body.error, "error");
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    next(page: number) {
      this.page = page;
      this.loadDatas();
    },
    reloadFilters() {
      this.btnReloadLoading = true;
      this.startPeriod = this.defaultStartPeriod;
      this.endPeriod = this.defaultEndPeriod;
      this.doDelayedSearch();
    },
    doDelayedSearch() {
      this.dialogFiltrosMobile = false;
      this.page = 1;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.loadDatas();
      }, 500);
    },
    formataValor(data: number) {
      const valor = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(data);

      return valor.toString().replace("R$", "").trim();
    },
    downloadReport(format: string) {
      this.btnDownloadLoading = true;
      Overlay.show();

      Api.get("/v2/client/financeiro/export", {
        params: {
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          entryType: this.selectedEntryTipoMovimentos.id,
          filterType: this.selectedEntryFiltrosMovimentos.id,
          page: this.page,
          export: this.toExport,
          format: format
        },
        responseType: "arraybuffer",
      })
        .then((response) => {

          let fileType = "";
          let fileExtension = "";
          switch (format) {
            case "PDF":
              fileType = "application/pdf";
              fileExtension = ".pdf";
              break;
            case "XLSX":
              fileType = "application/vnd.ms-excel";
              fileExtension = ".xlsx";
              break;

          }
          const blob = new Blob([response.data], {
            type: fileType,
          });
        
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            "extrato_" + this.startPeriod + "-" + this.endPeriod + fileExtension;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
        })
        .finally(() => {
          Overlay.hide();
          this.btnDownloadLoading = false;
        });
    },
    loadDatas() {
      this.errors = [];

      const intervalo = Math.round(
        (new Date(this.endPeriod).getTime() -
          new Date(this.startPeriod).getTime()) /
        (1000 * 3600 * 24)
      );

      if (intervalo > 31) {
        Snackbar.show(
          "O intervalo de pesquisa não pode ser superior a 31 dias",
          "error"
        );
        return false;
      }
      this.hasLoadedData = true;
      Overlay.show();

      this.loadingDesserts = true;
      Api.get("/v2/client/financeiro/movimentacao", {
        params: {
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          entryType: this.selectedEntryTipoMovimentos.id,
          filterType: this.selectedEntryFiltrosMovimentos.id,
          page: this.page,
          export: this.toExport,
        },
      })
        .then((response) => {
          const data = response.data.body.efetivadas.rows;
          const groups = data.reduce((groups: any, rows: any) => {
            const date = rows.data.split(" ")[0];
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(rows);
            return groups;
          }, {});

          const groupArrays = Object.keys(groups).map((date) => {
            return {
              date,
              rows: groups[date],
            };
          });

          this.desserts = groupArrays;
          this.filtrosMobile =
            moment(this.startPeriod).locale(this.lang).format("DD/MM/yyyy") +
            "-" +
            moment(this.endPeriod).locale(this.lang).format("DD/MM/yyyy");
          this.totalDesserts = Number(
            response.data.body.efetivadas.count.__total
          );
          this.pages = response.data.body.efetivadas.count.pages;
          this.totalEntradas = response.data.body.efetivadas.entradas;
          this.totalSaidas = response.data.body.efetivadas.saidas;
          this.somaDaPagina = response.data.body.efetivadas.somaPaginaFormatado;
          this.somaTotal = response.data.body.efetivadas.somaTotalFormatado;
          this.btnDownloadDisabled = this.totalDesserts == 0;
          this.panel = [
            ...Array(response.data.body.efetivadas.count.__this).keys(),
          ].map((k, i) => i);
          Overlay.hide();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
        })
        .finally(() => {
          this.loadingDesserts = false;
          this.btnReloadLoading = false;
        });
    },
    getBalance() {
      this.loadingBalance = true;
      Api.get("/v2/client/financeiro/saldo")
        .then((response) => {
          this.saldoCliente = response.data.body.saldoFormatado;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
        })
        .finally(() => {
          this.loadingBalance = false;
        });
    },
  },
});
