
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import QRCode from "qrcode";
import Swal from "sweetalert2";
import NewSwapSale from "../swaps/SwapsNewSell.vue";
import Auth from "@/lib/Auth";
import moment from "moment";
import { TableOptions } from "@/models/TableOptions";
import { Snackbar } from "@/lib/Snackbar";
import router from "@/router";
import { DepositoPix } from "@/models/DepositoPix";

export interface Swaps {
  id: string;
  swap: {
    currencyIn: {
      name: string;
      currencySymbol: string;
    };
    formattedValue: any;
    status: {
      key: any;
    };
    updatedAt: any;
    networkIn: {
      nameId: string;
      currencySymbol: string;
    };
    amountIn: string;
  };
  // Adicione outras propriedades se necessário
}
interface AddressDeposit {
  address: string;
  amountIn: string;
  netAmountOut: string;
}

interface PixData {
  qrCodeImage: string;
  valorFormatado: string;
  codigoQR: string;
  swapId: string;
  status: string;
  updatedAt: string;
  address: AddressDeposit[]; // Adiciona a propriedade address
}


export default Vue.extend({
  name: "UserSwaps",
  props: {
    item: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      btnLoading: false,
      loadingBaseData: true,
      loadingQRCode: false,
      moduloAtivo: true,
      dialogPix: false,
      form: {} as DepositoPix,
      dialogPIN: false,
      isMobile: false,
      idPixChecking: "",
      ckStatusPagto: false,
      progressTempoCkPix: 0,
      progressCkPixShow: true,
      progressQueryPix: false,
      progressPixCkinterval: 0,
      PixData: {} as PixData,
      amountIn: '',
      currencyInName: '',
      netWorkIn: '',
      swapId: '',
      user: "",
      totalResults: 0,
      breadcrumbs: [{
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.Crypto"),
          disabled: false,
          href: "/cripto",
        },
        {
          text: this.$t("components.preCadastro.template.form.SwapsSell"),
          disabled: true,
          href: "/cripto/swaps/sell",
        },
      ],
      //   {
      //     text: this.$t("components.preCadastro.template.form.Home"),
      //     disabled: false,
      //     href: "/",
      //   },
      //   {
      //     text: this.$t("components.preCadastro.template.form.Crypto"), 
      //     disabled: false,
      //     href: "/cripto",
      //   },
      //   {
      //     text: this.$t("components.preCadastro.template.form.SwapsSell"),
      //     disabled: true,
      //     href: "/cripto/swaps/sell",
      //   },
      // ],
      showSaldo: false,
      loadingDesserts: true,
      totalDesserts: 0,
      options: {} as TableOptions,
      search: "",
      moment: moment,
      lang: navigator.language,
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      timeout: 0,
      dessertsSwaps: new Array < Swaps > (),
      headersSwaps: [] as Array < {
        text: string;value: string;sortable: boolean
      } > ,
      ModuloInfo: [] as any,
    };
  },
  created() {
    if (!Auth.mfaEnabled) {
      router.push("/");
    } else {
      this.getInfo();
    }
    this.headersSwaps = this.getTranslatedHeaders();
  },
  components: {
    NewSwapSale,
  },

  watch: {
    '$i18n.locale': function() {
      this.updateHeaders();
      this.updateBreadcrumbs();
    }
  },
  methods: {
    getTranslatedBreadcrumbs() {
      return [{
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.Crypto"),
          disabled: false,
          href: "/cripto",
        },
        {
          text: this.$t("components.preCadastro.template.form.SwapsSell"),
          disabled: true,
          href: "/cripto/swaps/sell",
        },
      ];
    },

    updateBreadcrumbs() {
      this.breadcrumbs = [{
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.Crypto"),
          disabled: false,
          href: "/cripto",
        },
        {
          text: this.$t("components.preCadastro.template.form.SwapsSell"),
          disabled: true,
          href: "/cripto/swaps/sell",
        },
      ];
    },
    queryAndIndeterminate() {
      if (this.ckStatusPagto) {
        this.progressQueryPix = true;
        this.progressCkPixShow = true;
        this.progressTempoCkPix = 0;

        setTimeout(() => {
          this.progressQueryPix = false;

          this.progressPixCkinterval = setInterval(() => {
            if (this.progressTempoCkPix === 100) {
              if (this.ckStatusPagto) {
                this.checkStatusPagamento();
              }
              clearInterval(this.progressPixCkinterval);
              this.progressCkPixShow = false;

              return setTimeout(this.queryAndIndeterminate, 1000);
            }
            this.progressTempoCkPix += 10;
          }, 1000);
        }, 1000);
      }
    },

    checkStatusPagamento() {
      Overlay.show();
      Api.get(
          "/v2/client/financeiro/adicionar/pix/status/" + this.idPixChecking
        )
        .then((response) => {
          if (response.data.body.status === "APROVADO") {
            this.ckStatusPagto = false;
            router.push("/movimento");
          }
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          // Trate o erro conforme necessário
        })
        .finally(() => {
          this.btnLoading = false;
          Overlay.hide();
        });
    },
    getAddress(): string {
      return this.PixData.address.length > 0 ? this.PixData.address[0].address : '';
    },
    getTranslatedHeaders() {
      return [{
          text: this.$t("components.preCadastro.template.form.SourceCurrency").toString(),
          value: "swap.currencyIn",
          sortable: false
        },
        {
          text: this.$t("components.preCadastro.template.form.TargetCurrency").toString(),
          value: "swap.currencyOut",
          sortable: false
        },
        {
          text: this.$t("components.preCadastro.template.form.Input").toString(),
          value: "swap.amountIn",
          sortable: false
        },
        {
          text: this.$t("components.preCadastro.template.form.Output").toString(),
          value: "swap.netAmountOut",
          sortable: false
        },
        {
          text: this.$t("components.preCadastro.template.form.Fees").toString(),
          value: "swap.fees",
          sortable: false
        },
        {
          text: this.$t("components.preCadastro.template.form.Rate").toString(),
          value: "swap.price.amount",
          sortable: false
        },
        {
          text: this.$t("components.preCadastro.template.form.Status").toString(),
          value: "swap.status",
          sortable: false
        },
      ];
    },
    updateHeaders() {
      this.headersSwaps = this.getTranslatedHeaders();
    },

    async openDialogPix(item: Swaps) {
  this.dialogPix = true;
  this.loadingQRCode = true;
  this.ckStatusPagto = true;
  this.queryAndIndeterminate();

  try {
    const swapId = item.id;

    if (!swapId) {
      console.error("swapId não está definido");
      Swal.fire({
        text: "Erro ao obter o ID da transação. Por favor, tente novamente.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      this.loadingQRCode = false;
      return;
    }

    const currencySymbol = item.swap?.networkIn?.currencySymbol || 'BTC';

    const response = await Api.get(`/v2/client/cripto/swaps/address-deposit/?type=sell&currencyIn=${currencySymbol}&swapId=${swapId}`);

    const addressData = response.data.address;
    const qrCodeImage = await QRCode.toDataURL(addressData.address);

    this.PixData = {
      qrCodeImage,
      valorFormatado: item.swap.formattedValue,
      codigoQR: addressData.address,
      swapId: swapId,
      status: item.swap.status.key,
      updatedAt: item.swap.updatedAt,
      address: response.data.address || [],
    };

    this.amountIn = item.swap.amountIn;
    this.currencyInName = item.swap.currencyIn.name;
    this.dialogPix = true;

  } catch (error) {
    console.error("Erro ao gerar o QR Code", error);
    Swal.fire({
      text: "Erro ao gerar o QR Code. Por favor, tente novamente.",
      icon: "error",
      confirmButtonText: "Ok",
    });
  } finally {
    this.loadingQRCode = false;
  }
}
,

    closeDialogPix() {
      this.form.valor = "0";
      this.dialogPix = false;
      this.ckStatusPagto = false;
    },
    requestUse() {
      this.btnLoading = true;
      Overlay.show();
      Api.get("/v2/client/cripto/swaps/reverse")
        .then((response) => {
          Snackbar.show(response.data.body, "success");
          this.getInfo();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            this.moduloAtivo = false;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          Overlay.hide();
          this.btnLoading = false;
          this.loadingBaseData = false;
        });
    },
    getInfo() {
      this.loadingBaseData = true;
      Overlay.show();
      sessionStorage.removeItem("userCriptoId");

      Api.get("/v2/client/cripto/swaps/info")
        .then((response) => {
          this.user = response.data.body.id;


          this.ModuloInfo = response.data.body;

          sessionStorage.setItem("userCriptoId", response.data.body.id);

          if (this.user) {
            this.getSwaps();
          }
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 404) {
            this.moduloAtivo = false;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
        })
        .finally(() => {
          Overlay.hide();
          this.loadingBaseData = false;
        });
    },
    doCopy() {
      const addressDeposit = this.PixData.codigoQR;

      if (!addressDeposit) {
        Snackbar.show(this.$t("components.preCadastro.template.form.CopyError") as string, "error");
        return;
      }

      navigator.clipboard.writeText(addressDeposit).then(() => {
        Snackbar.show(this.$t("components.preCadastro.template.form.CopiedSuccessfully") as string);
      }).catch(() => {
        Snackbar.show(this.$t("components.preCadastro.template.form.CopyError") as string, "error");
      });
    },
    onCopy() {
      Snackbar.show(this.$t("components.preCadastro.template.form.CopiedSuccessfully") as string);
    },
    onCopyError() {
      Snackbar.show(this.$t("components.preCadastro.template.form.CopyError") as string, "error");
    },
    getSwaps() {
      if (!this.user) return;
      const { page } = this.options;
      this.loadingDesserts = true;
      Overlay.show();

      Api.get(`/v2/client/cripto/swaps/reverse/index/${this.user}`, {
          params: {
            type: 'sell',
            page: page,
          },
        })
        .then((response) => {
          if (response.data.data.rows.length > 0) {
            this.swapId = response.data.data.rows[0].id; // Armazena o id do primeiro item

            const isNewSwap = response.data.data.rows[0].swap.status.key === 'PENDING';

            if (isNewSwap) {
              // Exibe um alerta para o usuário antes de abrir o diálogo Pix
              Swal.fire({
                title: this.$t('components.preCadastro.template.form.NewSwapDetected') as string,
                text: this.$t('components.preCadastro.template.form.PleaseClickOKToProceed') as string,
                icon: "info",
                confirmButtonText: "OK",
              }).then(() => {
                // Abre o diálogo Pix após o usuário clicar em OK
                this.openDialogPix(response.data.data.rows[0]);
              });
            }
          }

          this.dessertsSwaps = response.data.data.rows as Swaps[];
          this.totalResults = response.data.data.count;
          this.totalDesserts = Number(response.data.data.count);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          Overlay.hide();
          this.loadingDesserts = false;
        });
    },
    getCurrencySymbol(symbol: string) {
      if (symbol === "p2p") {
        symbol = "brl";
      }
      return require(`@/assets/images/${symbol}.svg`);
    },
    getPrintableStatus(status: any) {
      let html = "";
      var icon = "<span></span>";
      html += '<div class="d-flex">';

      if (status.key === "COMPLETED") {
        icon =
          '<span size="14" color="text--success" >mdi mdi-check-circle</span>';
      }
      html += "<div>" + icon + "</div>";
      html += "<div>" + status.namePt + "</div>";
      html += "</div>";

      return html;
    },
  },

});
